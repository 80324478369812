import { configureStore, createSlice } from '@reduxjs/toolkit'
import soundSlice from './sound'

const mainSlice = createSlice({
    initialState: {
      game: {
        win: false,
        level: null,
        timer: 0,
        isValidSession: false,
        isFirstJoin: true
      },
      userData: {}
    },
    name: "main",
    reducers: {
      setLevel: (state, action) => {
        state.game.level = action.payload;
      },
      setWin: (state, action) => {
        state.game.win = action.payload;
      },
      setUserData: (state, action) => {
        state.userData = action.payload;
      },
      setTimer: (state, action) => {
        state.game.timer = action.payload;
      },
      setIsFirstJoin: (state, action) => {
        state.game.isFirstJoin = action.payload;
      }
    }
});

// Экспортируем действия
export const { setLevel, setWin, setUserData, setTimer, setIsFirstJoin } = mainSlice.actions;

// Настройка хранилища
const store = configureStore({
  reducer: {
    main: mainSlice.reducer,
    sound: soundSlice
  },
});

export default store;