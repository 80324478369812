import React, { useEffect, useState } from "react";
import MenuButton from "./elements/MenuButton";
import { useDispatch, useSelector } from "react-redux";
import { setIsFirstJoin } from "../store/store";

const MainPage = () => {

    const dispatch = useDispatch();
    const isFirstJoin = useSelector(state => state.main.game.isFirstJoin);

    useEffect(() => {
        // Проверяем первый ли это вход (для обучения)
        const isFirstJoin = JSON.parse(localStorage.getItem("isFirstJoin") ?? "true");
        if (isFirstJoin !== undefined) {
            dispatch(setIsFirstJoin(isFirstJoin));
        }
    }, []);

    return (
        <div className="e-flex e-flex_col e-flex_center MainPage">
            <MenuButton title="Играть" link={`${(isFirstJoin) ? "/learn" : "/map"}`} />
            <MenuButton title="Обучение" link="/learn" />
            <MenuButton title="Статистика" link="/stats" />
            <MenuButton title="Настройки" link="/settings" />
        </div>
    );
}

export default MainPage;