import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/App.css';
import MenuButton from './elements/MenuButton';
import { useDispatch, useSelector } from 'react-redux';
import { setWin, setLevel } from '../store/store';
import { checkPromo } from '../store/thunk/fetchData';
import { Howl } from 'howler';

const WinPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const level = useSelector(state => state.main.game.level);
    const isWin = useSelector(state => state.main.game.win); // Глобальное состояние win
    const [win, setLocalWin] = useState(false); // Локальное состояние win
    const timer = useSelector(state => state.main.game.timer);
    const winSound = useSelector(state => state.sound.win);
    const [promo, setPromo] = useState(false);

    
    const calcTime = (timer) => {
        const minutes = Math.floor((timer % 3600000) / 60000); // Минуты
        const seconds = Math.floor((timer % 60000) / 1000); // Секунды
        const ms = Math.floor((timer % 1000) / 10); // Килосекунды
        
        // Форматирование с добавлением ведущих нулей
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
        const formattedMs = String(ms).padStart(2, '0');
        
        return `${formattedMinutes}:${formattedSeconds}:${formattedMs}`;
    };
    
    const checkPromocode = () => {
        dispatch(checkPromo())
        .then((data) => {
            if (data.payload.data.status == "success") {
                setPromo(data.payload.data.sale);
            }
        });
    };
    
    useEffect(() => {
        if (isWin) {
            // Откладываем вывод окна победы, чтобы игрок увидел результат уровня
            setTimeout(() => {
                setLocalWin(true);
            }, 2000)
        } else {
            setLocalWin(false);
        }
    }, [isWin]);

    useEffect(() => {
        if (win) {
            // Музыка победы
            const music = new Howl(winSound);
            checkPromocode();
        }
    }, [win]);

    // Ссылки на текст и картинки в случае получения промокода
    const promoData = {
        1: {img: "https://e-cis.info/upload/iblock/707/ro4j1q7rz6n3a22vxiglwx9dxbkbip1u.jpg", text: "Поздравляем! Вы повысили уровень воды в Аральском море!"},
        3: {img: "https://homeclub-almaty.kz/wp-content/uploads/2019/06/e6c76cce13.jpg", text: "Отлично! Вы сократили расход воды в капчагайском водохранилище"},
        12: {img: "https://www.inbusiness.kz/uploads/13/images/YkY7yfzJ.jpg", text: "Здорово! Вы провели воду в водозабор Мангыстау!"},
    }
    const getPromoElement = () => {
        if (promo) {
            const i = parseInt(promo.split("%")[0]);
            
            console.log("PROMO: " + i)
            return (
                <div className='e-flex e-flex_col e-flex_jc'>
                    <p>{promoData[i].text}</p>
                    <img src={promoData[i].img} width={"100%"} height={"auto"} alt='promo' style={{alignSelf: "center", borderRadius: 20}}></img>
                </div>
            );
        } else if (level === "35") {
            return "Спасибо, что заботитесь о планете! Вы успешно прошли все уровни и помогли сохранить воду!"
        } else return ""
    }

    return (
        <div className={`WinPage ${(win) ? "fade-in" : ""} e-flex e-flex_col`}>
            <div className='e-flex e-flex_col e-flex_jb card'>
                <h2>Победа!</h2>
                <p>{calcTime(timer)}</p>
                { (level === "35") && "Спасибо, что заботитесь о планете! Вы успешно прошли все уровни и помогли сохранить воду!" }
                <div className='e-grid'>
                    {
                        (!promo) && <MenuButton title={">>>"} onClick={() => {
                            dispatch(setWin(false));
                            dispatch(setLevel(level + 1));
                            navigate((level !== "35") ? "/game/" + Number(+level + 1) : "/");
                            setPromo(false);
                        }} />
                    }
                    {
                        (promo) && (
                            <>
                                { getPromoElement() }
                                <p>Вы выиграли промокод на скидку {promo}!</p>
                                <div className='e-flex e-flex_jb'>
                                    <MenuButton title={"ОТКРЫТЬ"} onClick={() => {
                                        window.location.href = "https://grohe.promo?p=lk";
                                    }} />
                                    <MenuButton title={">>>"} onClick={() => {
                                        dispatch(setWin(false));
                                        dispatch(setLevel(level + 1));
                                        setPromo(false);
                                        navigate("/game/" + Number(+level + 1));
                                    }} />
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default WinPage;