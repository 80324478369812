import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MenuButton from "./elements/MenuButton";
import { setIsFirstJoin } from "../store/store";

const LearnPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const content = [
        {
            img: "/images/learn/img1.png",
            text: ["Цель каждого уровня - соединить вход и выход, чтобы устранить утечку.", "У вас не должно остаться труб, которые не соединены с другими!"],
            btn: "Далее"
        },
        {
            img: "/images/learn/img2.png",
            text: ["Достигайте уровней и получайте скидки! ", "🎯 Заработайте промокоды: до 12% на 16-м уровне."],
            btn: "Понятно"
        },
        {
            img: "/images/learn/img3.png",
            text: ["Моментальное получение промокода после прохождения подарочного уровня.", "Мы отправим вам по смс, чтобы вы его не потеряли."],
            btn: "Далее"
        },
        {
            img: "/images/learn/img4.png",
            text: ["Также ваши промокоды можно будет найти в Меню, нажав иконку подарка. "],
            btn: "Понятно"
        },
        {
            img: "/images/learn/img5.png",
            text: ["Каждую неделю мы разыгрываем призы среди участников игры в инстаграм аккаунте @grohekz.", "Трое самых ловких и быстрых игроков получат призы."],
            btn: "Понятно"
        },
        {
            img: "/images/learn/img5.png",
            text: ["Топ игроков можно посмотреть в Главном меню."],
            btn: "Начать игру"
        }
    ];

    const goToLevels = () => {
        localStorage.setItem("isFirstJoin", JSON.stringify(false));
        dispatch(setIsFirstJoin(false));
        navigate("/map");
    }

    return (
        <div className="e-flex e-flex_col">
            <h2>Обучение</h2>
            <div className="container LearnPage">
            {
                (content[page].img) && <div className="e-cover"><img src={content[page].img} alt="learn"></img></div>
            }
            {
                content[page].text.map(text => {
                    return (
                        <><p>{text}</p><br></br></>
                    )
                })
            }
            {
                (page === 2) && <div className="e-cover"><img src="/images/learn/img3_1.png" alt="learn"></img></div>
            }
            <MenuButton title={content[page].btn} onClick={() => {
                (content.length-1 > page) ? setPage(page+1) : goToLevels();
                console.log(content.length-1)
            }}/>
            </div>
        </div>
    )
}

export default LearnPage;