import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Howl } from "howler";

const MenuButton = (props) => {

    const isSoundsOn = useSelector(state => state.sound.settings.sound); // Настройка звук вкл./выкл.
    const clickSound = useSelector(state => state.sound.button);
    const sound = new Howl(clickSound);

    const handleClick = (event) => {
        event.stopPropagation();
        console.log(props.disabled)
        if (props.onClick && !props.disabled) {
            props.onClick();
        }
        if (isSoundsOn) {
            sound.play();
        }
    }

    return (!props.link) ? (<div className={`menu-button ${(props.type === 1) ?? "menu-button-blue"}`}><button className="" disabled={props.disabled} onClick={handleClick}>{props.title}</button></div>) :
    (<div className={`menu-button ${(props.type === 1) ? "menu-button-blue" : ""}`}><Link className="" onClick={handleClick} to={(!props.disabled) ? props.link : undefined}>{props.title}<br /><span>{props.desc}</span></Link></div>)
}

export default MenuButton;